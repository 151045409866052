<template>
  <div class="margin-top-bottom-twenty">
    <div
      v-show="$route.name !== 'SendFailed' && $route.name !== 'ExclusiveBook' && $route.name !== 'BatchSend'"
      :class="
        !($route.meta.hiddenMessageBar === false)
          ? 'margin-top-bottom-twenty'
          : ''
      "
      class="
        flex-between
        padding-right-left-20
        sub-menu
        border-radius-6
        box-shadow-light-grey
      "
    >
      <div class="row" v-if="!($route.meta.hiddenMessageBar === false)">
        <div style="padding-right: 15px; border-right: 1px solid #ececec">
          <el-select
            v-model="currentOfficial"
            placeholder="请选择公众号"
            @change="handleOfficialChange"
            style="width: 140px"
            filterable
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
        </div>
        <el-menu
          router
          mode="horizontal"
          style="border: 0"
          :default-active="activeSubMenu"
          @select="handleMenuClick"
          menu-trigger="click"
        >
          <template v-for="item in messageMenu">
            <el-menu-item
              :key="item.path"
              v-if="!item.special"
              class="margin-right-23 font-eighteen"
              :index="item.path"
            >
              <i class="iconfont" :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
          <!-- <el-menu-item
            class="margin-right-23 font-eighteen"
            index="/message/template"
          >
            <span slot="title">模板消息</span>
            <el-popover placement="bottom" width="500" trigger="click">
              <div>
                <el-input
                  v-model="searchWord"
                  placeholder="搜索公众号"
                  size="mini"
                  clearable
                  style="margin-bottom: 10px"
                  @clear="handleClearWord"
                >
                  <el-button
                    @click="hanleSearchWord"
                    slot="append"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </div>

              <div class="channel-box">
                <el-tag
                  style="margin: 4px; cursor: pointer"
                  type="success"
                  v-for="(item, index) in tmpList"
                  :key="index"
                  @click="handleChannelClick(item)"
                >
                  {{ item.channelName }}
                </el-tag>
              </div>
              <sup
                slot="reference"
                class="el-badge__content el-badge__content--undefined is-fixed"
              >
                {{ templateFinisheList.length }}
              </sup>
            </el-popover>
          </el-menu-item> -->

          <!-- <el-submenu index="/message/mediaResource" class="font-eighteen">
            <template slot="title">图文素材</template>
            <el-menu-item
              class="mediaResource"
              index="/message/mediaResource/media"
              >图文</el-menu-item
            >
            <el-menu-item
              class="mediaResource"
              index="/message/mediaResource/resource"
              >素材</el-menu-item
            >
          </el-submenu> -->
        </el-menu>
      </div>
    </div>
    <div>
      <router-view
        :accountChannelId.sync="currentOfficial"
        :relationId.sync="relationId"
        :channelList="channelList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { messageMenu } from "@/assets/js/nav";
import { messagePlatformList } from "@/assets/js/options"; //客服消息支持平台
import { getTemplageFinish } from "@/api/service";
export default {
  name: "Message",
  data() {
    return {
      currentOfficial: null,
      page: 0,
      loading: false,
      search: "",
      activeSubMenu: this.$route.meta.submenu,
      messageMenu,
      messagePlatformList,
      relationId: null, // 关联ID 用于发送失败 的跳转
      templateFinisheList: [],
      searchWord: "",
      tmpList: [],
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        this.activeSubMenu = newVal.meta.submenu || newVal.path;
        this.relationId = newVal.params.accountChannelId
          ? newVal.params.relationId
          : null;
        this.init();
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.init());
  },
  computed: {
    channelList() {
      const { officialList } = this;
      return officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      );
    },
    ...mapGetters(["officialList"]),
  },
  methods: {
    handleClearWord() {
      this.tmpList = this.templateFinisheList;
    },
    hanleSearchWord() {
      let tmpItem = this.templateFinisheList.filter((item) => {
        return item.channelName.includes(this.searchWord);
      });
      this.tmpList = tmpItem;
    },
    init() {
      const account = sessionStorage.getItem("messageAccount");
      this.currentOfficial = account
        ? Number(account)
        : this.channelList[0]
        ? this.channelList[0].id
        : null;
    },
    handleChannelClick(item) {
      this.currentOfficial = item.accountChannelId;
      this.handleOfficialChange(item.accountChannelId);
    },
    handleOfficialChange(select) {
      sessionStorage.setItem("messageAccount", JSON.stringify(select));
    },
    handleMenuClick(path) {
      if (path.includes("mediaResource")) {
        return false;
      }
      const menuData = this.messageMenu.find((item) => item.path === path);
      this.addRouterHistory({
        params: {},
        title: menuData.title,
        path: menuData.path,
      });
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  // created() {
  //   getTemplageFinish().then((res) => {
  //     this.templateFinisheList = res;
  //     this.tmpList = res;
  //   });
  // },
};
</script>

<style lang="scss" scoped>
/deep/ .el-menu-item.is-active {
  border-right: 0;
}
.sub-menu .el-menu-item,
.el-submenu__title {
  height: 52px;
  line-height: 52px;
}

.sub-menu {
  background-color: #fff;
  // padding: 0 15px;
}
.font-eighteen /deep/ .el-submenu__title {
  font-size: 18px !important;
}
.mediaResource {
  font-size: 15px !important;
  font-weight: bold !important;
}
.mediaResource.is-active {
  color: #00bf8a !important;
}
/deep/ .el-submenu__title {
  color: #1f2d3d !important;
}
/deep/ .el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  color: #1f2d3d !important;
}
/deep/ .el-menu-item.is-active {
  background-color: #fff !important;
  margin-right: 0 !important;
}
/deep/ .el-badge__content.is-fixed {
  top: 10px !important;
  cursor: pointer;
}
.channel-box {
  max-height: 500px;
  overflow-y: auto;
}
</style>
